import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Seo from "../components/seo"

const NotFoundPage = () => {
  const browser = typeof window !== "undefined" && window

  return (
    <>
      <Seo title="404: Not found"></Seo>
      {browser && (
        <div className="error__header column align-items-center justify-content-center">
          <h1 className="error__title">
            404: Nie udało nam się znaleźć podstrony
          </h1>
          <StaticImage
            src="../images/404.png"
            alt="Stłuczka samochodowa"
            className="error__image"
          />
          <p className="error__message">
            Próbujesz dostać się na podstronę, która nie istnieje.
          </p>
          <Link to="/">Wróć na stronę główną</Link>
        </div>
      )}
    </>
  )
}

export default NotFoundPage
